import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Breadcrumbs`}</h2>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{` > Apps > Add `}</li>
    </ul>
    <h2>{`Dashboard`}</h2>
    <p>{`The dashboard provides a comprehensive overview of conversion performance linked to Google Ads campaigns by displaying key data like conversions and sending states or sent last two days, and last processed input Adwords or first processed.`}</p>
    <h2>{`Conversions`}</h2>
    <p>{`Listing panel of the conversions.`}</p>
    <h2>{`Conversion actions`}</h2>
    <p>{`Where you are able to syncronize actions and create loacally new conversion action.`}</p>
    <h2>{`Settings`}</h2>
    <p>{`On `}<em parentName="p">{`Settings`}</em>{` is possible to test the connection, define the `}<em parentName="p">{`Number of Google Conversion Steps`}</em>{` and the `}<em parentName="p">{`Google Conversion Step Name`}</em>{`. It also possible to define a `}<em parentName="p">{`New conversion event`}</em>{` and their properties:`}</p>
    <ul>
      <li parentName="ul">{`Google Click Id`}</li>
      <li parentName="ul">{`Conversion name`}</li>
      <li parentName="ul">{`Conversion time`}</li>
      <li parentName="ul">{`Conversion price`}</li>
      <li parentName="ul">{`Conversion value`}</li>
      <li parentName="ul">{`Currency`}</li>
      <li parentName="ul">{`Contact date`}</li>
      <li parentName="ul">{`Customer phone`}</li>
      <li parentName="ul">{`CoreMedia_CID`}</li>
      <li parentName="ul">{`External campaign`}</li>
      <li parentName="ul">{`Lead Origin`}</li>
      <li parentName="ul">{`Inbound Virtual Number`}</li>
      <li parentName="ul">{`Call Type`}</li>
      <li parentName="ul">{`Product Landing`}</li>
      <li parentName="ul">{`Contact URL`}</li>
      <li parentName="ul">{`Adwords Campaign`}</li>
      <li parentName="ul">{`Reaction time`}</li>
      <li parentName="ul">{`Call Status`}</li>
      <li parentName="ul">{`Funnel Qualification`}</li>
      <li parentName="ul">{`Service`}</li>
      <li parentName="ul">{`Client type`}</li>
      <li parentName="ul">{`Quotation`}</li>
      <li parentName="ul">{`Test A/B`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      